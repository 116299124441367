<template>
	<div class="register">
		<div class="header">
			<div>新增干部</div>
			<div>
				<button @click="openDraftClick"><i class="iconfont icon-caogaoxiang-copy"></i> 草稿箱</button>
				<button @click="expand =! expand"><i class="iconfont icon-shouqi1"></i>{{expand ? '收起文件' : '展开文件'}}</button>
			</div>
		</div>
		<div class="body">
			<div class="preview" v-show="expand">
				<FileViewer v-if="filePath" :filePath="filePath"></FileViewer>
				<div v-else class="prompt">
					<img src="@/assets/file.png" alt="">
					<p>请<span class="select" @click="selectFile">选择文件</span></p>
					<p>如《干部任免审批表.docx》</p>
				</div>
			</div>
			<div class="form">
				<div class="form-header">
					<div class="select-file">
						<button class="btn operate-btn" @click="selectFile">
							<i class="iconfont icon--_xuanze"></i>
							<span>选择文件</span>
						</button>
						<span class="ml10">左侧区域选择《干部任免审批表》进行预览</span>
					</div>
					<div class="right">
						<button class="btn plain-btn" @click="submit(1)"
							v-permission="['/hrms/user/change/register-create']">存草稿</button>
						<button class="btn submit-btn ml20" @click="submit(0)"
							v-permission="['/hrms/user/change/register-create']">提交</button>
					</div>
				</div>
				<div class="detail-container">
					<Detail :detailInfo="detailInfo" :isDetail="false" :cadreFileList="cadreFileList" :hasPrint="false"></Detail>
				</div>
			</div>
		</div>
		<input v-show="false" type="file" ref="singleInput" @change="singleInputChange">

		<el-dialog :visible.sync="openDraft" width="60%" :title="`草稿箱(${tableData.length}/10)`">
			<CommonTable :headers="headers" :tableData="tableData">
				<template slot="operate" slot-scope="{ row }">
					<div class="operate">
						<button style="color:#008B61;" @click="copyDraft(row)"
							v-permission="['/hrms/user/change/query-by-id']">继续录入</button>
						<button class="ml15" style="color:#FF2525;" type="text" size="small" @click="delDraft(row)"
							v-permission="['/hrms/user/change/delete-drafts']">删除</button>
					</div>
				</template>
			</CommonTable>
		</el-dialog>
	</div>
</template>

<script>
import Detail from "@/components/detail";
import { uploadFile } from "@/api/fileAPI.js";
import {
  getDraftList,
  createRegister,
  delDraft,
  getFormData,
} from "@/api/hrAPI.js";
import { education } from "@/components/table/data";

export default {
  components: {
    Detail,
  },
  data() {
    return {
      detailInfo: {
        educationInfoList: [],
        positionInfo: {},
        biographicalNotesInfoList: [],
        assessmentSituationsInfo: {},
        userRelationInfoList: [],
        presentationInfo: {},
        fileInfoList: [],
        draft: 0,
        nation: "汉族",
      },
      openDraft: false,
      headers: [
        {
          label: "姓名",
          value: "name",
        },
        {
          label: this.$replaceText("现任部门"),
          value: "orgName",
        },
        {
          label: "更新时间",
          value: "updateTime",
        },
        {
          label: "操作",
          slot: "operate",
        },
      ],
      tableData: [],
      expand: true,
      filePath: "",
      cadreFileList: [],
    };
  },
  computed: {
    formId() {
      return this.$route.query.id;
    },
  },
  created() {
    if (this.formId) {
      getFormData({ id: this.formId }).then((res) => {
        this.detailInfo = res.extra.formData;
      });
    }
  },
  methods: {
    selectFile() {
      this.$refs.singleInput.click();
    },
    singleInputChange(ev) {
      const file = ev.target.files[0];

      const note = `上传文件`;
      const path = "/hrms/file/upload/attachment";
      this.$store.commit("status/SET_LOG", { path, note });

      uploadFile({ file }).then((res) => {
        // this.cadreFileList.unshift(res)
        this.filePath = res.filePath;
        this.detailInfo.fileInfoList.push({
          dirName: "干部任免审批表",
          ...res,
        });
      });
      this.$refs.singleInput.value = "";
    },
    // 后端字段为枚举类型，不能为''
    checkEducationInfoList() {
      education.forEach((item) => {
        if (item.require && !this.detailInfo.educationInfoList[0][item.value]) {
          this.$set(this.detailInfo.educationInfoList[0], item.value, null);
        }
      });
    },
    submit(draft) {
      this.detailInfo.draft = draft;
      const note = draft ? "保存登记信息草稿" : "提交登记信息";
      const path = "/hrms/user/change/register-create";
      this.$store.commit("status/SET_LOG", { path, note });
      //   this.$setLogNote(path, encodeURIComponent(note))
      // console.log('checkEducationInfoList')
      // this.checkEducationInfoList();
      createRegister(this.detailInfo).then((res) => {
        this.$message.success("保存成功");
        if (draft === 0) {
          this.detailInfo = {
            educationInfoList: [],
            positionInfo: {},
            biographicalNotesInfoList: [],
            assessmentSituationsInfo: {},
            userRelationInfoList: [],
            presentationInfo: {},
            fileInfoList: [],
            draft: 0,
            nation: "汉族",
          };
          this.filePath = "";
          location.search = ""; // 解决重提的数据提交后，表单数据仍存在的bug
        }
      });
    },
    openDraftClick() {
      getDraftList().then((res) => {
        this.openDraft = true;
        this.tableData = res;
      });
    },
    copyDraft(row) {
      //  草稿箱的信息覆盖到当前表单
      getFormData({ id: row.id }).then((res) => {
        this.detailInfo = res.extra.formData;
        if (!this.detailInfo.fileInfoList) {
          this.detailInfo.fileInfoList = [];
        }
        if (!this.detailInfo.educationInfoList) {
          this.detailInfo.educationInfoList = [];
        }
        this.openDraft = false;
      });
    },
    delDraft(row) {
      this.$confirm("确认删除草稿？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //   const note = 	`删除${row.updateTime}的登记信息草稿`
          //   const path = '/hrms/user/change/delete-drafts'
          //   this.$store.commit('status/SET_LOG', {path, note})
          delDraft({ id: row.id }).then((res) => {
            this.$message.success("删除成功");
            this.openDraftClick();
          });
        })
        .catch(() => {});
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.register {
  padding: 0 20px 20px;
  .header {
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    color: #101010;
    padding: 0 16px;
    font-size: 16px;
    button {
      margin-left: 30px;
      i {
        margin-right: 8px;
      }
    }
  }
  .body {
    height: calc(100% - 56px);

    display: flex;
    justify-content: space-between;
    > div {
      flex-grow: 1;
      height: 100%;
    }
    .preview {
      width: 45%;
      margin-right: 20px;
      border: 1px solid #000;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      .prompt {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .select {
          color: #3291f8;
          text-align: center;
          cursor: pointer;
        }
        p {
          margin-top: 30px;
        }
        p:last-child {
          margin-top: 10px;
        }
      }
    }
    .form {
      width: 55%;
      background-color: #fff;
      padding: 0 20px;
      .form-header {
        height: 76px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .select-file {
          color: #666;
        }
        .right {
        }
      }
      .detail-container {
        height: calc(100% - 76px);
        padding-bottom: 20px;
        box-sizing: border-box;
        overflow: auto;
      }
    }
  }
}
</style>